// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectDetailsPanel from "../_components/UserProjectDetailsPanel/UserProjectDetailsPanel.res.js";
import * as UserInternetRfpProject_Details_Location from "./UserInternetRfpProject_Details_Location.res.js";
import * as ProjectRequiredProducts_CheckmarkableOptions from "../_components/ProjectRequiredProducts/ProjectRequiredProducts_CheckmarkableOptions.res.js";
import * as UserInternetRfpProject_Details_RequiredProductsScss from "./UserInternetRfpProject_Details_RequiredProducts.scss";
import * as UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription from "./UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.res.js";

var css = UserInternetRfpProject_Details_RequiredProductsScss;

function panelHeading(panelHeading$1) {
  return JsxRuntime.jsx("h4", {
              children: panelHeading$1,
              className: css.panelHeading
            });
}

function UserInternetRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions(props) {
  var options = props.options;
  if (options !== undefined && options.length !== 0) {
    return JsxRuntime.jsx("div", {
                children: JsxRuntime.jsx(ProjectRequiredProducts_CheckmarkableOptions.make, {
                      options: options,
                      cssStart: "SwitchLight"
                    }),
                className: css.propertiesContainer
              });
  } else {
    return null;
  }
}

var CheckmarkableOptions = {
  make: UserInternetRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions
};

function UserInternetRfpProject_Details_RequiredProducts_ProductPanel(props) {
  var __isExpanded = props.isExpanded;
  var internetRfpConfiguration = props.internetRfpConfiguration;
  var isExpanded = __isExpanded !== undefined ? __isExpanded : false;
  var requirements = internetRfpConfiguration.additionalRequirements;
  return JsxRuntime.jsx(UserProjectDetailsPanel.make, {
              className: css.panel,
              heading: props.heading,
              isExpanded: isExpanded,
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs(JsxRuntime.Fragment, {
                            children: [
                              JsxRuntime.jsx(UserInternetRfpProject_Details_Location.make, {
                                    internetRfpConfiguration: internetRfpConfiguration
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: "Internet Service",
                                    className: css.productName
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Access Type",
                                                  value: internetRfpConfiguration.accessType
                                                }),
                                            className: Cx.cx([
                                                  css.propertyLeft,
                                                  css.stayShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Port Speed",
                                                  value: Belt_Option.getWithDefault(internetRfpConfiguration.portSpeed, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Committed Data Rate",
                                                  value: String(internetRfpConfiguration.committedDataRateValue) + (" " + internetRfpConfiguration.committedDataRateUnit)
                                                }),
                                            className: Cx.cx([
                                                  css.propertyLeft,
                                                  css.switchLight
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "Burstable",
                                                  value: internetRfpConfiguration.burstable
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.stayLight
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "IPv4 Addresses",
                                                  value: Belt_Option.getWithDefault(internetRfpConfiguration.ipv4Addresses, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyLeft,
                                                  css.stayShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductServiceDescription.make, {
                                                  label: "IPv6 Addresses",
                                                  value: Belt_Option.getWithDefault(internetRfpConfiguration.ipv6Addresses, "")
                                                }),
                                            className: Cx.cx([
                                                  css.propertyRight,
                                                  css.switchShaded
                                                ])
                                          }),
                                      JsxRuntime.jsx(UserInternetRfpProject_Details_RequiredProducts_ProductPanel$CheckmarkableOptions, {
                                            options: internetRfpConfiguration.internetServices
                                          })
                                    ],
                                    className: css.propertiesContainer
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: "Additional Service Requirements",
                                    className: css.productName
                                  }),
                              requirements !== undefined ? JsxRuntime.jsx("div", {
                                      children: requirements
                                    }) : null
                            ],
                            className: css.requirementsBlock
                          })
                    ],
                    className: css.panelBody
                  })
            });
}

var make = UserInternetRfpProject_Details_RequiredProducts_ProductPanel;

export {
  css ,
  panelHeading ,
  CheckmarkableOptions ,
  make ,
}
/* css Not a pure module */
